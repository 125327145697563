export enum LocalStorageKey {
  REMOVE_SUMMARY_LINEBREAKS = 'remove_summary_linebreaks',
  MARKING_COLOR = 'marking_color',
  SUMMARIES_VIEWED_COUNT = 'summaries_viewed_count',
  SUMMARY_RATING_DISMISSED_DATE = 'summary_rating_dismissed_date',
  NPS_SURVEY_SHOW_LATER = 'nps_survey_show_later',

  // Flag to be used in e2e tests to prevent tutorial popups
  PREVENT_TUTORIALS = 'prevent_tutorials',
  ANNOTATION_STATUS = 'annotation_status',

  // E2E
  ALLOW_VAIA_REDIRECTION_IN_E2E_ENVIRONMENT = 'allow_vaia_redirection_in_e2e_environment',
}
